<template>
  <div class="practice-container">
    <div class="practice-content">
      <div class="menu-list">
        <el-menu
          :default-active="activeMenu"
          class="el-menu-vertical-demo"
          :router="true"
          unique-opened
        >
          <template v-for="item in menuList">
            <el-submenu
              v-if="item.children && item.children.length"
              :index="item.path + ''"
              :key="item.title"
            >
              <template slot="title">
                <i :class="item.icon + ' mr-8'"></i>
                <span>{{ item.title }}</span>
              </template>
              <el-menu-item
                :index="el.path + ''"
                v-for="el in item.children"
                :key="el.title"
              >
                <span slot="title">{{ el.title }}</span>
              </el-menu-item>
            </el-submenu>
            <el-menu-item v-else :index="item.path + ''" :key="item.title">
              <span slot="title">{{ item.title }}</span>
            </el-menu-item>
          </template>
        </el-menu>
      </div>
      <div class="divContainer">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { StaffTypeEnum } from "@/utils/constant";
export default {
  computed: {
    RoleType() {
      const { matched } = this.$route;
      if (matched.some(({ path }) => path.includes("enterprise"))) {
        return StaffTypeEnum.Enterprise;
      } else if (matched.some(({ path }) => path.includes("school"))) {
        return StaffTypeEnum.School;
      }
      return undefined;
    },
    menuList() {
      let list = [
        // {
        //   title: "信息管理",
        //   icon: "iconfont iconshixishixun-shixibaoming",
        //   path: "/enterprise/practice",
        //   children: [
        //     { title: "合作项目", path: "/enterprise/staff" },
        //     { title: "投入资源", path: "/enterprise/staff" },
        //     { title: "产品服务", path: "/enterprise/staff" },
        //     { title: "人才培养", path: "/enterprise/staff" },
        //     { title: "员工发展", path: "/enterprise/staff" }
        //   ]
        // },
        // { title: "投入资源", path: "/enterprise/staff",icon: "iconfont iconshixishixun-shixibaoming", },
        // { title: "产品服务", path: "/enterprise/staff",icon: "iconfont iconshixishixun-shixibaoming", },
        // { title: "人才培养", path: "/enterprise/staff",icon: "iconfont iconshixishixun-shixibaoming", },
        // { title: "员工发展", path: "/enterprise/staff",icon: "iconfont iconshixishixun-shixibaoming", }
        {
          title: "员工管理",
          path: "/enterprise/staff",
          icon: "iconfont iconshixishixun-shixibaoming"
        },
        {
          title: "合作项目",
          path: "/enterprise/project",
          icon: "iconfont iconshixishixun-shixibaoming"
        },
        {
          title: "投入资源",
          path: "/enterprise/resourceInvestment",
          icon: "iconfont iconshixishixun-shixibaoming"
        },
        {
          title: "人才培养方案",
          path: "/enterprise/personnelTraining",
          icon: "iconfont iconshixishixun-shixibaoming"
        },
        {
          title: "企业发展路径",
          path: "/enterprise/developmentPath",
          icon: "iconfont iconshixishixun-shixibaoming"
        },
        {
          title: "产品服务",
          path: "/enterprise/productionService",
          icon: "iconfont iconshixishixun-shixibaoming"
        }
      ];

      return list;
    },
    activeMenu() {
      let route = this.$route;
      return route.path;
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.practice-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #f5f5f5;
  padding: 24px;
  .practice-content {
    display: flex;
    height: 100%;
    position: relative;
    .menu-list {
      position: absolute;
      top: 0;
      left: 0;
      width: 240px;
      height: 100%;
      overflow-y: auto;
      background-color: #fff;
      margin-right: 24px;
      border-radius: 8px;
      /deep/ .el-menu {
        border: none;
        .el-menu-item.is-active {
          color: #3d5afe;
          background-color: rgba(61, 90, 254, 0.1);
        }
      }
    }
    .divContainer {
      height: 100%;
      flex: 1;
      background-color: #fff;
      position: relative;
      margin-left: 264px;
      border-radius: 8px;
      .page-content {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        padding: 24px;
        background-color: #fff;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        overflow: auto;
      }
    }
  }
}
.el-menu-item.is-active {
  background: rgba($color: #3d5afe, $alpha: 0.08);
  position: relative;
  font-weight: bold;

  &::before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 4px;
    height: 24px;
    background: #3d5afe;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    border-radius: 0px 4px 4px 0px;
  }
}
</style>
